<template>
    <Listbox 
        :modelValue="selectedOption"
        @update:modelValue="selectOption"
    >
        <div class="relative">
            <ListboxButton
                class="relative w-full form-input text-left "
            >
                <span v-if="label" class="block truncate">
                    {{ label }}
                </span>

                <span v-else class="block truncate text-gray-500">
                    {{ placeholder }}
                </span>

                <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4"/></svg>
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-600 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                >
                    <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option"
                        as="template"
                    >
                        <li
                            :class="[
                                active
                                    ? 'text-white bg-gray-700'
                                    : 'text-gray-50',
                                'cursor-default select-none relative py-2 pl-10 pr-4',
                            ]"
                        >
                            <span
                                :class="[
                                    selected
                                        ? 'font-medium'
                                        : 'font-normal',
                                    'block truncate',
                                ]"
                                >{{ keyLabel(option) }}</span
                            >
                            <span
                                v-if="selected"
                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
    import { ref, watchEffect, computed } from "vue";
    import {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
    } from "@headlessui/vue";

    


    export default {
        components: {
            Listbox,
            ListboxLabel,
            ListboxButton,
            ListboxOptions,
            ListboxOption,
        },
        props: {
            modelValue: {
                type: null
            },
            options: {
                type: [Array, Object],
                default: () => []
            },
            keyLabel: {
                type: Function,
                default: item => item
            },
            keyValue: {
                type: Function,
                default: item => item
            },
            compareFn: {
                type: Function,
                default: (option, value) => JSON.stringify(option) === JSON.stringify(value)
            },
            placeholder: {
                type: String,
                default: 'Select an option'
            }
        },
        setup(props, { emit }) {
            const selectedValue = ref(props.modelValue);
            watchEffect(() => selectedValue.value = props.modelValue);

            const selectedOption = ref();
            watchEffect(() => {
                selectedOption.value = props.options 
                                     ? props.options.find(option => props.compareFn(props.keyValue(option), selectedValue.value))
                                     : [];
            });
            
            const label = computed(() => selectedOption.value ? props.keyLabel(selectedOption.value) : null);

            function selectOption(option) {
                selectedValue.value = props.keyValue(option);
                emit('update:modelValue', selectedValue.value);
            }

            return {
                selectedOption,
                selectOption,
                label
            };
        },
    };
</script>

<template>
    <MainLayout>
        <div class="mt-16 px-6 py-4 flex-1 flex flex-col bg-white rounded-t-3xl">
            <div class="-mt-20 flex">
                <div class="mx-auto w-40 h-40 flex justify-center items-center bg-gradient-to-tr from-indigo-500 to-pink-300 rounded-2xl overflow-hidden">
                    <img 
                        :src="require(`@/assets/gifs/${feedbackIcon}.gif`)"
                        class="w-80 h-80 object-cover"
                    />
                </div>
            </div>
            
            <div class="mt-4 flex justify-between items-center">
                <h1 class="text-3xl text-gray-800 font-semibold">
                    Feedback
                </h1>
            </div>

            <div class="mt-6">
                <label class="block">
                    <p class="text-sm text-gray-400">Elije el movimiento</p>

                    <Select 
                        v-model="selectedBalance"
                        :options="balances"
                        :key-label="(b) => b.product?.name"
                        class="mt-2 w-full"
                    />
                </label>

                <label class="mt-4 block">
                    <p class="text-sm text-gray-400">Elije el item a revisar</p>

                    <Select 
                        v-model="selectedItem"
                        :options="selectedBalance?.balanceItems"
                        :key-label="(i) => i.item?.name"
                        class="mt-2 w-full"
                    />
                </label>
            </div>

            <div class="mt-4">
                <div class="flex justify-between items-center">
                    <p class="text-sm text-gray-400">Feedback</p>

                    <div class="form-input py-1">
                        {{ form.rating }}
                    </div>
                </div>

                <InputRange 
                    v-model="form.rating"
                    :min="1"
                    :max="5"
                    class="mt-4"
                />
            </div>

            <label class="mt-8 block">
                <p class="">
                    <span class="font-bold">Comentario</span>
                    <span class="ml-1 text-xs text-gray-400">(Opcional)</span>
                </p>

                <textarea 
                    v-model="form.message"
                    class="mt-2 h-48 form-textarea w-full"
                ></textarea>
            </label>

            <div class="mt-8">
                <button
                    class="px-4 py-3 w-full text-xl text-gray-200 bg-gray-700 rounded-xl"
                    @click="createFeedback"
                >
                    Enviar
                </button>
            </div>
        </div>
    </MainLayout>
</template>

<script>
    import { ref, computed } from "vue";

    import MainLayout from '/src/layouts/main';
    import Select from '/src/components/select';
    import InputRange from '@/components/input-range';

    import Order from '/src/api/endpoints/Order.js';
    import Review from '/src/api/endpoints/Review.js';
    import usePromise from "/src/composables/use-promise.js";
    import { alert } from '/src/components/alerts';
    
    import { format } from '/src/utils/dates.js';
    import { state } from '/src/store';

    const icons = ['face_vomiting', 'face_weary', 'face_thinking', 'face_winking', 'face_drooling'];

    export default {
        name: "Feedback",
        components: {
            MainLayout,
            Select,
            InputRange
        },
        setup() {
            const user = computed(() => state.user);

            // balances
            const selectedBalance = ref();
            const selectedItem = ref();
            const {
                result: balances = [],
                use: getBalances,
            } = usePromise(() => {
                return Order.all({
                    query: {
                        employeeId: user.value.id,
                        status: 'delivered',
                        date: format(new Date, 'yyyy-MM-dd')
                    }
                });
            });

            getBalances();

            // feedback
            const feedbackIcon = computed(() => icons[form.value.rating - 1]);
            const form = ref({
                message: '',
                rating: 3
            });

            const {
                use: createFeedback
            } = usePromise(async () => {
                await Review.create({
                    ...form.value,
                    balanceId: selectedBalance.value.id,
                    itemId: selectedItem.value.item.id
                });

                alert({
                    title: 'Feedback enviado',
                    text: `¡Tu comentario ha sido enviado correctamente!`,
                    confirmLabel: 'Ok',
                    cancelLabel: false,
                    confirm: async ({ close }) => {
                        resetFeedback();
                        close();
                    }
                });
            });

            function resetFeedback() {
                form.value = { message: '', rating: 3 };
                selectedBalance.value = null;
                selectedItem.value = null;
            }


            return {
                // balances
                selectedBalance,
                selectedItem,
                balances,

                // feedback
                form,
                feedbackIcon,
                createFeedback
            };
        },
    };
</script>
import EndpointPrivate from "../EndpointPrivate";

class Order extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'bought-menus', param];		
	}
};

export default new Order;

